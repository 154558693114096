import { Action, AudioActions, PlayerState } from "./types"

export function audioReducer(state: PlayerState, action: Action): PlayerState {
  switch (action.type) {
    case AudioActions.SET_META:
      return { ...state, episode: action.payload }
    case AudioActions.PLAY:
      return { ...state, playing: true }
    case AudioActions.TOGGLE_MUTE:
      return { ...state, muted: !state.muted }
    case AudioActions.SET_CURRENT_TIME:
      return { ...state, currentTime: action.payload }
    case AudioActions.SET_DURATION:
      return { ...state, duration: action.payload }
    case AudioActions.REMOVE_EPISODE:
      return { ...state, episode: null, playing: false, currentTime: 0 }
    case AudioActions.PAUSE:
    default:
      return { ...state, playing: false }
  }
}
