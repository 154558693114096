import React, { createContext, useMemo, useState } from 'react';

interface AppContextType {
  user: { email: string; password: string } | null;
  isAudioPlayerSmall: boolean;
  setIsAudioPlayerSmall: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppContext = createContext<AppContextType>({ user: null, isAudioPlayerSmall: false, setIsAudioPlayerSmall: () => undefined });

export const AppProvider = ({ children }: React.PropsWithChildren) => {
  const [user, setUser] = useState(null);
  const [isAudioPlayerSmall, setIsAudioPlayerSmall] = useState(false)

  const ctx = useMemo(() => ({ user, setUser, isAudioPlayerSmall, setIsAudioPlayerSmall }), [user, isAudioPlayerSmall]);
  return (
    <AppContext.Provider value={ctx}>
      {children}
    </AppContext.Provider>
  )
};
