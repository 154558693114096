/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { createContext, useContext, useMemo, useReducer, useRef } from 'react'
import { AudioActions, Episode, PlayerAPI, PublicPlayerActions } from './types'
import { audioReducer } from './utils'

export const AudioPlayerContext = createContext<PlayerAPI | null>(null)

export const AudioProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(audioReducer, {
    playing: false,
    muted: false,
    duration: 0,
    currentTime: 0,
    episode: null,
  })
  const playerRef = useRef<React.ElementRef<'audio'>>(null)
  const actions = useMemo<PublicPlayerActions>(() => {
    const itIsPlaying = (episode?: Episode) => episode
      ? state.playing && playerRef.current?.currentSrc === episode.enclosures?.url
      : state.playing

    return {
      play(episode) {
        if (episode) {
          dispatch({ type: AudioActions.SET_META, payload: episode })
          if (
            playerRef.current &&
            playerRef.current.currentSrc !== episode.enclosures.url
          ) {
            const { playbackRate } = playerRef.current
            playerRef.current.src = episode.enclosures.url
            playerRef.current.load()
            playerRef.current.pause()
            playerRef.current.playbackRate = playbackRate
            playerRef.current.currentTime = 0
          }
        }

        playerRef.current?.play()
      },
      pause() {
        playerRef.current?.pause()
      },
      toggle(episode) {
        if (itIsPlaying(episode)) {
          actions.pause()
        } else {
          actions.play(episode)
        }
      },
      seekBy(amount) {
        if (playerRef.current) {
          playerRef.current.currentTime += amount
        }
      },
      seek(time) {
        if (playerRef.current) {
          playerRef.current.currentTime = time
        }
      },
      playbackRate(rate) {
        if (playerRef.current) {
          playerRef.current.playbackRate = rate
        }
      },
      toggleMute() {
        dispatch({ type: AudioActions.TOGGLE_MUTE })
      },
      isPlaying(episode) {
        return itIsPlaying(episode)
      },
      removeEpisode() {
        dispatch({ type: AudioActions.REMOVE_EPISODE })
      },
    }
  }, [state.playing])

  const api = useMemo<PlayerAPI>(
    () => ({ ...state, ...actions }),
    [state, actions],
  )

  return (
    <>
      <AudioPlayerContext.Provider value={api}>
        {children}
      </AudioPlayerContext.Provider>
      <audio
        ref={playerRef}
        onPlay={() => dispatch({ type: AudioActions.PLAY })}
        onPause={() => dispatch({ type: AudioActions.PAUSE })}
        onTimeUpdate={(event) => {
          dispatch({
            type: AudioActions.SET_CURRENT_TIME,
            payload: Math.floor(event.currentTarget.currentTime),
          })
        }}
        onDurationChange={(event) => {
          dispatch({
            type: AudioActions.SET_DURATION,
            payload: Math.floor(event.currentTarget.duration),
          })
        }}
        muted={state.muted}
      />
    </>
  )
}

export function useAudioPlayer(episode?: Episode) {
  const player = useContext(AudioPlayerContext)

  return useMemo<PlayerAPI>(
    () => ({
      ...player!,
      play() {
        if (player) {
          player.play(episode)
        }
      },
      toggle() {
        if (player) {
          player.toggle(episode)
        }
      },
      removeEpisode() {
        if (player) {
          player.pause()
          player.removeEpisode()
        }
      },
      get playing() {
        if (player) {
          return player!.isPlaying(episode)
        }
        return false
      },
    }),
    [player, episode],
  )
}
