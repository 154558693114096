/* eslint-disable no-unused-vars */
import type { EpisodeFeedType } from "../types"

export type Episode = EpisodeFeedType

export interface PlayerState {
  playing: boolean
  muted: boolean
  duration: number
  currentTime: number
  episode: Episode | null
}

export interface PublicPlayerActions {
  play: (episode?: Episode) => void
  pause: () => void
  toggle: (episode?: Episode) => void
  seekBy: (amount: number) => void
  seek: (time: number) => void
  playbackRate: (rate: number) => void
  toggleMute: () => void
  isPlaying: (episode?: Episode) => boolean
  removeEpisode: () => void
}

export type PlayerAPI = PlayerState & PublicPlayerActions

// eslint-disable-next-line no-shadow
export enum AudioActions {
  SET_META = 'SET_META',
  PLAY = 'PLAY',
  PAUSE = 'PAUSE',
  TOGGLE_MUTE = 'TOGGLE_MUTE',
  SET_CURRENT_TIME = 'SET_CURRENT_TIME',
  SET_DURATION = 'SET_DURATION',
  REMOVE_EPISODE = 'REMOVE_EPISODE',
}

export type Action =
  | { type: AudioActions.SET_META; payload: Episode }
  | { type: AudioActions.PLAY }
  | { type: AudioActions.PAUSE }
  | { type: AudioActions.TOGGLE_MUTE }
  | { type: AudioActions.SET_CURRENT_TIME; payload: number }
  | { type: AudioActions.SET_DURATION; payload: number }
  | { type: AudioActions.REMOVE_EPISODE }
